import React from 'react';
import { Modal, Form, Input, message, Button } from 'antd';
import { postRegister } from '../../../helpers/apis';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenLogin, setIsOpenRegister } from '../../../Redux/counterSlice';

const ModalRegister = () => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const isOpenRegister = useSelector((state) => state.counter.isOpenRegister)
    const valueEmail = useSelector((state) => state.counter.valueEmail)
    const onFinish = (values) => {
        const input =
        {
            name: values.fullName,
            phone_number: values.phoneNumber,
            email: values.email,
            password: values.password
        }
        setIsLoading(true);
        postRegister(input)
            .then((res) => {
                if (res?.data.status === 200) {

                    message.success(res?.data.message)
                    dispatch(setIsOpenLogin(true))
                    dispatch(setIsOpenRegister(false))
                }
                if (res?.data.status >= 400) {
                    message.error(res?.data.message)
                }
            })
            .catch((e) => {
                if (e?.response?.status >= 400) {
                    message.error(e?.response?.data.message)
                }
            })
            .finally(() => {
                setIsLoading(false);
            })

    }
    const PATTERN_EMAIL =
        /^[\s\S]*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(?= *$)/;

    return (
        <Modal
            title={<div className='text-center'>Đăng ký</div>}
            centered
            open={isOpenRegister}
            onOk={() => dispatch(setIsOpenRegister(false))}
            onCancel={() => dispatch(setIsOpenRegister(false))}
            width={500}
            footer={false}
        >
            <Form

                className='mt-[20px]'
                initialValues={{ remember: true,email:valueEmail}}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Họ và tên "
                    name="fullName"
                    rules={[{ required: true, message: 'Vui lòng nhập tài khoản của bạn!' }]}
                >
                    <Input placeholder='Họ và tên' className=' sm:max-w-[320px]  float-right' />
                </Form.Item>
                <Form.Item
                    label="Số Điện thoại "
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Vui lòng nhập số điện thoại của bạn!' }]}
                >
                    <Input className=' sm:max-w-[320px]  float-right' placeholder='Số điện thoại' />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true }, {
                        pattern: PATTERN_EMAIL,
                        message: "Định dạng Email không hợp lệ",
                    },]}
                >
                    <Input
                        className='sm:max-w-[320px] float-right' placeholder='Email' />
                </Form.Item>
                <Form.Item
                    label="Mật khẩu"
                    name="password"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu của bạn!' }]}
                >
                    <Input.Password className='sm:max-w-[320px] float-right' placeholder='Password' />
                </Form.Item>

                <Form.Item className='text-center  '>
                    <Button loading={isLoading} className='uppercase mt-2.5 cursor-pointer bg-[#ff5421] min-w-full rounded-md  text-[#fff] ' htmlType="submit">
                        Đăng ký
                    </Button>
                </Form.Item>
                <Form.Item>
                    <span>Bạn đã có tài khoản ? <span onClick={() => {
                        dispatch(setIsOpenLogin(true))
                        dispatch(setIsOpenRegister(false))
                    }} className='cursor-pointer hover:underline text-blue-600'>Đăng nhập</span></span>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalRegister