import React, {useState} from 'react';
import {Modal, Form, Input, message, Button} from 'antd';
import {postLogin} from '../../../helpers/apis';

import {useDispatch, useSelector} from 'react-redux';
import {setIsOpenForgotPassword, setIsOpenLogin, setIsOpenRegister} from '../../../Redux/counterSlice';

const ModalLogin = () => {

    const dispatch = useDispatch()
    const isOpenLogin = useSelector((state) => state.counter.isOpenLogin)


    const [isLoading, setIsLoading] = useState(false)
    const onFinish = (values) => {
        const input = {
            email: values.email,
            password: values.password
        }
        setIsLoading(true);
        postLogin(input)
            .then((res) => {
                if (res?.data?.status === 200) {
                    localStorage.setItem("userName", res.data.user.name)
                    localStorage.setItem("email", res.data.user.email)
                    localStorage.setItem("tokenUser", res?.data?.authorisation.token)
                    localStorage.setItem("phoneNumber", res.data.user.phone_number)
                    message.success(res?.data.message)
                    // setIsOpenLogin(false)
                    dispatch(setIsOpenLogin(false))
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000)
                }
                if (res?.data.status >= 400) {
                    message.error(res?.data.message)
                }
            })
            .catch((e) => {
                if (e?.response?.status >= 400) {
                    message.error(e?.response?.data.message)
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    const PATTERN_EMAIL =
        /^[\s\S]*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(?= *$)/;
    return (
        <Modal
            title={<div className='text-center'>Đăng nhập</div>}
            centered
            open={isOpenLogin}
            onOk={() => dispatch(setIsOpenLogin(false))}
            onCancel={() => dispatch(setIsOpenLogin(false))}
            width={450}
            footer={false}
        >
            <Form
                className='mt-[20px]'
                initialValues={{remember: true}}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Tài khoản"
                    name="email"
                    rules={[{required: true}, {
                        pattern: PATTERN_EMAIL,
                        message: "Định dạng Email không hợp lệ",
                    },]}
                >
                    <Input placeholder='Tên đăng nhập'/>
                </Form.Item>

                <Form.Item
                    label="Mật khẩu"
                    name="password"
                    rules={[{required: true, message: 'Vui lòng nhập mật khẩu của bạn!'}]}
                >
                    <Input.Password placeholder='Mật khẩu'/>
                </Form.Item>

                <Form.Item className='text-center '>
                    <Button loading={isLoading}
                            className='uppercase cursor-pointer bg-[#ff5421] min-w-full  rounded-md  text-[#fff] '
                            htmlType="submit">
                        Đăng nhập
                    </Button>
                </Form.Item>
                <Form.Item>
                    <span onClick={() => {
                        dispatch(setIsOpenLogin(false))
                        dispatch(setIsOpenForgotPassword(true))


                    }} className='block mb-2 text-violet-600 cursor-pointer underline '> Quên mật khẩu </span>
                    <span>Ban chưa có tài khoản ? <span className='cursor-pointer text-blue-600' onClick={() => {
                        dispatch(setIsOpenRegister(true))
                        dispatch(setIsOpenLogin(false))


                    }}>Đăng ký ngay</span></span>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalLogin