import React from 'react'

const Home = React.lazy(() => import('../components/Pages/HomePage/HomePage'))
const Blog = React.lazy(() => import('../components/Pages/BlogPage/BlogPage'))
const Detail = React.lazy(() => import('../components/Pages/Detail/Detail'))
const courses = React.lazy(() => import('../components/Pages/CoursesPage/CoursesPage'))
const Introduce = React.lazy(() => import('../components/Pages/Introduce/Introduce'))
const Contact = React.lazy(() => import('../components/Pages/Contact/Contact'))
const Profile = React.lazy(() => import('../components/Pages/Profile/Profile'))
const BlogDetail = React.lazy(() => import('../components/Pages/BlogDetail/BlogDetail'))
const BooksPage = React.lazy(() => import('../components/Pages/BookPage/Books'))
const BooksDetailPage = React.lazy(() => import('../components/Pages/BookPage/BooksDetailPage'))
const routePortals = {
    HOME: '/',
    BLOG: '/tin-tuc',
    DETAIl: '/trang-chi-tiet/:id',
    COURSES: "/khoa-hoc",
    INTRODUCE: '/gioi-thieu',
    CONTACT: '/lien-he',
    PROFILE: '/trang-ca-nhan',
    BLOGDETAIL: '/chi-tiet-tin-tuc/:id',
    BOOKS: '/mua-sach',
    BOOKDetail: '/mua-sach/:id',
    BOOKDetailStaff: '/mua-sach/:id/:staff',
}

export const routePortal = [
    {
        id: 1,
        route: routePortals.HOME,
        component: Home,
        permission: 'dashboard'
    },
    {
        id: 2,
        route: routePortals.BLOG,
        component: Blog,
        permission: 'dashboard'
    },
    {
        id: 3,
        route: routePortals.DETAIl,
        component: Detail,
        permission: 'dashboard'
    },
    {

        id: 4,
        route: routePortals.INTRODUCE,
        component: Introduce,
        permission: 'dashboard'
    },
    {
        id: 5,
        route: routePortals.COURSES,
        component: courses,
        permission: 'dashboard'
    },
    {
        id: 6,
        route: routePortals.CONTACT,
        component: Contact,
        permission: 'dashboard'
    },
    {
        id: 7,
        route: routePortals.PROFILE,
        component: Profile,
        permission: 'dashboard'
    },
    {
        id: 8,
        route: routePortals.BLOGDETAIL,
        component: BlogDetail,
        permission: 'dashboard'
    },
    {
        id: 9,
        route: routePortals.BOOKS,
        component: BooksPage,
        permission: 'dashboard'
    },   {
        id: 9,
        route: routePortals.BOOKDetail,
        component: BooksDetailPage,
        permission: 'dashboard'
    },  {
        id: 10,
        route: routePortals.BOOKDetailStaff,
        component: BooksDetailPage,
        permission: 'dashboard'
    },
]