import { instanceAxios } from "./request";

export const postRegister = (user) => instanceAxios.post(`/register`, user)
export const postLogin = (user) => instanceAxios.post(`/login`, user)
export const postDetail = (id) => instanceAxios.post(`/course/detail`, id)
export const postContact = (data) => instanceAxios.post(`/user/contact`, data)
export const postLogout = () => instanceAxios.post(`/logout`)
export const getListCourse = () => instanceAxios.post(`/course/list`)
export const getListCourseList = (data) => instanceAxios.post(`/course/list`,data)
export const getListCourseTop = (number) => instanceAxios.post(`/course/list`,{number:number})
export const getListCourseTypesNew = () => instanceAxios.get(`/type/list`)
export const postPayment = (info) => instanceAxios.post(`/receipt/create`, info)
export const getListEducation = () => instanceAxios.post(`/education/list`)
export const getListLecturers = () => instanceAxios.post(`/lecturer/list`)
export const postForgotPassword = (email) => instanceAxios.get(`/user/forgot-password`, {params:email})
export const getMyCourse = () => instanceAxios.post("/course/owner")
export const getTransactionHistory = () => instanceAxios.post("/user/receipts")

export const postListBlog = () => instanceAxios.post("/blog/list")
export const postListBook = () => instanceAxios.post("/book/list")
export const postListBlogTop = (number) => instanceAxios.post("/blog/list",{number:number})
export const postListBlogDetail = (idBlog) => instanceAxios.post("/blog/detail", idBlog)
export const postListBookDetail = (idBook) => instanceAxios.post("/book/detail", idBook)
export const postPaymentBook = (data) => instanceAxios.post("/book/receipt", data)
export const updateUserOner = (user) => instanceAxios.post("/user/update", user)
export const acceptUserCourse = (data) => instanceAxios.post("admin/course/accept", data)
