import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
);

const navConfig = [
    {
        title: 'Tổng Quan',
        path: '/',
        icon: icon('ic_analytics'),
    },
    {
        title: 'Danh Mục',
        path: '/category',
        icon: icon('category-list-svgrepo-com'),
    },
    {
        title: 'Khóa học',
        path: '/products',
        icon: icon('ic_cart'),
    },
    {
        title: 'Dịch vụ',
        path: '/educations',
        icon: icon('education-svgrepo-com'),
    },
    {
        title: 'Tin Tức',
        path: '/blog',
        icon: icon('ic_blog'),
    },
    {
        title: 'Người Dùng ',
        path: '/user',
        icon: icon('ic_user'),
    },
    {
        title: 'Thanh Toán',
        path: '/payment',
        icon: icon('payment-methods-svgrepo-com'),
    },
    {
        title: 'Mã giảm giá',
        path: '/discount',
        icon: icon('discount-percentage-svgrepo-com'),
    },
    {
        title: 'Thiết lập',
        path: '/settings',
        icon: icon('settings-gear-svgrepo-com'),
    },
];

export default navConfig;
