import React, { useState } from 'react';
import { Modal, Form, Input, message, Button } from 'antd';
import { postForgotPassword, postLogin } from '../../../helpers/apis';
import { setIsOpenLogin, setIsOpenRegister, setIsOpenForgotPassword } from '../../../Redux/counterSlice';
import { useDispatch, useSelector } from 'react-redux';
const ModalForgotPassword = () => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const isOpenForgotPassword = useSelector((state) => state.counter.isOpenForgotPassword)
    const onFinish = (values) => {
        const input = {
            email: values.email,
        }
        setIsLoading(true);
        postForgotPassword(input)
            .then((res) => {
                if (res?.data?.status === 200) {
                    message.success(res?.data.message)
                    dispatch(setIsOpenLogin(false))

                    setTimeout(() => {
                        dispatch(setIsOpenForgotPassword(false))

                    }, 1000)
                }
                if (res?.data.status >= 400) {
                    message.error(res?.data.message)
                }
            })
            .catch((e) => {
                if (e?.response?.status >= 400) {
                    message.error(e?.response?.data.message)
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    const PATTERN_EMAIL =
        /^[\s\S]*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(?= *$)/;
    return (
        <Modal
            title={<div className='text-center'>Quên mật khẩu</div>}
            centered
            open={isOpenForgotPassword}
            onOk={() => dispatch(setIsOpenForgotPassword(false))}
            onCancel={() => dispatch(setIsOpenForgotPassword(false))}
            width={400}
            footer={false}
        >
            <Form
                className='mt-[20px]'
                initialValues={{ remember: true }}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Email "
                    name="email"
                    rules={[{ required: true }, {
                        pattern: PATTERN_EMAIL,
                        message: "Định dạng Email không hợp lệ",
                    },]}

                >
                    <Input placeholder='Email ' />
                </Form.Item>

                <Form.Item className='text-center '>
                    <Button loading={isLoading} className='uppercase cursor-pointer bg-[#ff5421] min-w-full  rounded-md  text-[#fff] ' htmlType="submit">
                        Xác nhận
                    </Button>
                </Form.Item>
                <Form.Item>

                    <button className='cursor-pointer block text-center text-blue-600' onClick={() => {

                        dispatch(setIsOpenForgotPassword(false))
                        dispatch(setIsOpenLogin(true))

                    }}>Quay lại</button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForgotPassword