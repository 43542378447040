// ----------------------------------------------------------------------
const username =localStorage.getItem('userName')
const email =localStorage.getItem('email')
const phoneNumber =localStorage.getItem('phoneNumber')
export const account = {
  displayName: `${username}`,
  email: `${email}`,
  phoneNumber:`${phoneNumber}`,
  photoURL: '/assets/images/avatars/avatar_25.jpg',
};
