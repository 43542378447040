import axios from "axios";

const getDomaint = () =>{
    let domain = document.location;
    switch (domain.origin){
        case "https://tiksell.vn":
            return "https://tiksell.vn/public/api";
        case "http://localhost:3000":
            return "http://tiksell-backend.com/api";
            case "http://localhost:3001":
            return "http://tiksell-backend.com/api";
        default:
            return "https://tiksell.vn/public/api";
    }
}
export const instanceAxios = axios.create({
    baseURL: getDomaint(),
    headers: { 'X-Custom-Header': 'foobar' }
});
instanceAxios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("tokenUser") ?? ''


    if (token) {

        config.headers = {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json , application/msn.api.v1+json',
        }

    } else {
        config.headers = {
            Accept: 'application/json, application/msn.api.v1+json',
        }
    }


    return config;
})