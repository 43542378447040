import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard'


export const IndexPage = lazy(() => import('../pages/app'));
export const BlogPage = lazy(() => import('../pages/blog'));
export const EducationPage = lazy(() => import('../pages/education'));
export const ContactPage = lazy(() => import('../pages/contact'));
export const UserPage = lazy(() => import('../pages/user'));
export const LoginPage = lazy(() => import('../pages/login'));
export const ProductsPage = lazy(() => import('../pages/products'));
export const Page404 = lazy(() => import('../pages/page-not-found'));
export const Payment = lazy(()=>import('../pages/Payment'))
export const Category = lazy(()=>import('../pages/Category'))
export const GetoneCategory = lazy(()=>import('../pages/GetoneCategory'))
export const AddBlogPage = lazy(()=>import('../pages/AddBlog'))
export const DiscountPage = lazy(()=>import('../pages/discount'))
export const AddProduct =lazy(()=>import('../pages/AddProduct'))
export const AddEducation =lazy(()=>import('../pages/AddEducation'))
export const AddTeacher =lazy(()=>import('../pages/AddTeacher'))
export const AddItem =lazy(()=>import('../pages/AddItem'))
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'edit-products/:id', element: <AddProduct /> },
        { path: 'add-products', element: <AddProduct /> },
        { path: 'add-item', element: <AddItem /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'settings', element: <ContactPage /> },
        { path: 'educations', element: <EducationPage /> },
        { path: 'edit-education/:id', element: <AddEducation /> },
        { path: 'add-education', element: <AddEducation /> },
        { path: 'discount', element: <DiscountPage /> },
        { path: 'add-teacher', element: <AddTeacher /> },
        { path: 'edit-teacher/:id', element: <AddTeacher /> },
        { path: 'payment', element: <Payment></Payment> },
        { path: 'category', element: <Category></Category> },
        { path: 'add-blog', element: <AddBlogPage></AddBlogPage> },
        { path: 'editcategory/:id', element: <GetoneCategory></GetoneCategory> },
        { path: 'edit-blog/:id', element: <AddBlogPage></AddBlogPage> },

      ],
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
