import {
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import {Helmet} from 'react-helmet';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {Suspense, useState, useEffect} from 'react';
import LayoutPage from "./components/layout/layoutPage";
import {routePortal} from "./routers/PortalRoute";
import './App.css'
import {useScrollToTop} from './Admin/components/hooks/use-scroll-to-top'
import Router from './Admin/routes/sections'
import ThemeProvider from './Admin/theme'
// import Loading from "./components/Loading/Loading";
import Loaddingapp from "./components/Loading/Loaddingapp";
import {getoneUser} from "./helpers/adminapi";
import {message} from "antd";

function App() {
    useScrollToTop();
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState('1');
    const role_lever = localStorage.getItem('email');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getoneUser({email: role_lever});
                if (response && response.data) {
                    if (response.data.status === 200) {
                        setRole(response.data.data.role_level)
                    } else {
                        message.error(response.data.message);
                    }
                }
            } catch (error) {

            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [role_lever]);
    if (loading) {
        return <Loaddingapp/>;
    }
    return (
        <div>
            <Helmet>
                <link rel="shortcut icon" type="image/x-icon" href="/assets/img/a3.png"/>
            </Helmet>
            <div className="App">
                {role === 2 ? (
                    <ThemeProvider>
                        <Router/>
                    </ThemeProvider>
                ) : (
                    <Suspense fallback={<Loaddingapp/>}>
                        <Routes>
                            <Route path='/' element={<LayoutPage>
                                <Outlet/>
                            </LayoutPage>
                            }
                            >
                                <Route path='/'>
                                    {routePortal.map(route => (
                                        <Route key={route.id} path={route.route} element={<route.component/>}/>
                                    ))}
                                </Route>
                            </Route>
                        </Routes>
                    </Suspense>
                )}
            </div>
        </div>
    );
}

export default App;
