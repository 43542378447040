import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import style from "./Footer.module.scss";
import {
    EnvironmentOutlined,
    PhoneOutlined,
    MailOutlined,
    ClockCircleOutlined,
    FacebookOutlined,
    InstagramOutlined,
    YoutubeOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {setIsOpenRegister, setInjetValueEmail} from "../../Redux/counterSlice";
import {useDispatch, useSelector} from "react-redux";
import {getListCourseTypesNew} from "../../helpers/apis";
import {getConfig} from "../../helpers/adminapi";

function Footer(props) {
    const valueMail = useSelector((state) => state.counter.valueEmail)
    const [dataCategories, setDataCategories] = useState([])
    const [config, setConfig] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem("types")) {
            getListCourseTypesNew().then((res) => {
                localStorage.setItem("types", JSON.stringify(res?.data?.data))
                setDataCategories(res?.data?.data)
            }).catch((e) => {
            });
        } else {
            let local = localStorage.getItem("types");
            setDataCategories(JSON.parse(local));
        }
        getConfig().then((res) => {
            let data = res?.data?.data;
            setConfig(data)
        }).catch((error) => {
        });

    }, []);

    function windowScroll() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={`${style.container} bg-[black] relative `}>
            <Row
                className={
                    "container max-lg:py-4 max-lg:flex-col bg-[#171F32] flex items-center justify-center footer shadow-md rounded-md absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                }
            >

                <Col span={24} lg={12} className=" hidden sm:flex items-center  justify-start max-lg:mb-4 lg:p-[60px]">
                    <img
                        src="https://keenitsolutions.com/products/wordpress/educavo/wp-content/uploads/2020/10/newsletter.png"
                        className="footer_img"
                        alt=""
                    ></img>
                    <p className="text-[white] text-[20px] sm:text-[25px]  lg:text-[36px] ms-8">
                        Nhập Email
                    </p>
                </Col>
                <Col span={24} lg={12}>
                    <div className="flex items-center justify-start">
                        <input
                            value={valueMail}
                            onChange={(e) => dispatch(setInjetValueEmail(e.target.value))}
                            disabled={localStorage.getItem("userName") ? true : false}
                            type="text"
                            placeholder="Nhập Email của bạn "
                            className={`footer-input ${localStorage.getItem("userName") && "opacity-90"}`}
                        ></input>
                        <button onClick={() => {
                            if (!localStorage.getItem("userName")) {
                                dispatch(setIsOpenRegister(true));
                            }
                        }} className="py-[20px] inline-block px-10 bg-[#FF5421] text-[#fff]  flex items-center">
                            Đăng ký
                        </button>
                    </div>
                </Col>
            </Row>
            <Row className=" bg-[black] pt-[80px] sm:pt-[100px] lg:pt-[150px] max-lg:ml-4  ">
                <div className="container mx-auto px-4 flex justify-center">
                    <Col span={24} lg={6}>
                        <img src="/assets/img/a5.png" className="h-[50px] mb-9" alt=""></img>
                        {/*<p className="text-[white] px-3 text-[15px]">*/}
                        {/*    Học Viện TikSell thuộc công ty TNHH Truyền thông và sáng tạo MME.*/}
                        {/*</p>*/}
                        <p className="text-[white] px-3 text-[15px] mt-2">
                            TikSell – Nơi cung cấp các khóa học xây dựng kênh TikTok chuyên
                            nghiệp cho cá nhân và doanh nghiệp. Nâng tầm thương hiệu – Đột phá
                            doanh thu.
                        </p>
                        <div className="flex items-center justify-start px-3">
                            <a
                                href={config?.link_facebook ?? ""}
                                className="text-[white] text-[20px] mr-5 mt-3 hover:text-[#ff5421] transition duration-200 ease-in-out underline-none"
                            >
                                <FacebookOutlined/>
                            </a>
                            <a
                                href={config?.link_instagram ?? ""}
                                className="text-[white] text-[20px] mr-5 mt-3 hover:text-[#ff5421] transition duration-200 ease-in-out underline-none"
                            >
                                <InstagramOutlined/>
                            </a>
                            <a
                                href={config?.link_youtube ?? ""}
                                className="text-[white] text-[20px] mt-3 hover:text-[#ff5421] transition duration-200 ease-in-out underline-none"
                            >
                                <YoutubeOutlined/>
                            </a>
                        </div>
                    </Col>
                    <Col span={24} lg={8} className="lg:pl-9 max-lg:mt-5 text-[white]">
                        <div className="text-[white] text-[20px] mb-10 relative inline-block">
                            Địa chỉ
                            <span className="absolute -bottom-2 left-0 h-[2px] bg-[#ff5421] w-2/4"></span>
                        </div>
                        <div className="flex items-center justify-start mb-3.5">
                            <EnvironmentOutlined className="text-[#FF5421] text-[20px]"/>
                            <p className="text-[15px] ml-8">
                                {config?.address ?? ""}
                            </p>
                        </div>
                        <div className="flex items-center justify-start mb-3.5">
                            <PhoneOutlined className="text-[#FF5421] text-[20px]"/>
                            <a href={`https://zalo.me/${config?.link_zalo ?? ""}`}
                               className="text-[15px] ml-8">   {config?.link_zalo ?? ""}</a>
                        </div>
                        <div className="flex items-center justify-start mb-3.5">
                            <MailOutlined className="text-[#FF5421] text-[20px]"/>
                            <p className="text-[15px] ml-8"> {config?.email ?? ""}</p>
                        </div>
                        <div className="flex items-center justify-start mb-3.5">
                            <ClockCircleOutlined className="text-[#FF5421] text-[20px]"/>
                            <p className="text-[15px] ml-8"> {config?.open_time ?? ""}</p>
                        </div>
                        <div>
                            <img src="https://tiksell.vn/wp-content/uploads/2023/04/logoSaleNoti-1-300x114.png" alt=""
                                 className="w-2/4 mt-5"></img>
                        </div>
                    </Col>
                    <Col span={24} lg={4} className="lg:pl-9 max-sm:hidden max-lg:mt-5 text-[white]">
                        <div className="text-[white] text-[20px] mb-10 relative inline-block">
                            Danh mục
                            <span className="absolute -bottom-2 left-0 h-[2px] bg-[#ff5421] w-2/4"></span>
                        </div>
                        <ul>
                            {dataCategories.map((item, index) => {
                                return <Link to="/khoa-hoc" key={index}>
                                    <li className="hover:text-[#ff5421] transition duration-200 ease-in-out underline-none flex justify-start items-center mb-3">
                                        <p className="w-[4px] h-[4px] bg-[#ff5421] mr-3"></p>{" "}
                                        <p>{item?.name}</p>
                                    </li>
                                </Link>
                            })}
                        </ul>
                    </Col>
                    <Col span={24} lg={6} className="text-[white] max-sm:hidden">
                        <iframe
                            title="Fanpage"
                            src="https://www.facebook.com/v12.0/plugins/page.php?adapt_container_width=true&app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3674b3b20b7f9%26domain%3Dtiksell.vn%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ftiksell.vn%252Ff7c9d0a25ac76c%26relation%3Dparent.parent&container_width=258&height=250&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Ftiksell.vn&locale=vi_VN&sdk=joey&show_facepile=true&small_header=false&tabs=timeline&width="
                            width="340"
                            height="300"
                            style={{border: 'none', overflow: 'hidden'}}
                            allowtransparency="true"
                            allow="encrypted-media"
                        ></iframe>
                    </Col>
                </div>
                <div className="border-2 border-[#439978] w-full mt-5">
                </div>
                <div className="text-[white] flex justify-between m-auto items-center w-[80%] mt-6 mb-6">
                    <p className='text-[16px]'>Copyright © 2023 <a href="http://mediasoft.vn/">MediaSoft</a></p>
                    <div className=" hidden sm:flex  justify-center items-center">
                        <Link to='/gioi-thieu' onClick={windowScroll}>
                            <p className='text-[16px] hover:text-[#ff5421] transition duration-200 ease-in-out underline-none cursor-pointer'>Giới
                                thiệu</p>
                        </Link>
                        <span className="h-[10px] w-[10px] bg-[#ff5421] rounded-full ml-5 mr-5"></span>
                        <Link to='/tin-tuc' onClick={windowScroll}>
                            <p className='text-[16px] hover:text-[#ff5421] transition duration-200 ease-in-out underline-none cursor-pointer'>Tin
                                tức</p>
                        </Link>
                        <span className="h-[10px] w-[10px] bg-[#ff5421] rounded-full ml-5 mr-5"></span>
                        <Link to='/lien-he' onClick={windowScroll}>
                            <p className='text-[16px] hover:text-[#ff5421] transition duration-200 ease-in-out underline-none pr-4 cursor-pointer'> Liên
                                hệ</p>
                        </Link>
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default Footer;
