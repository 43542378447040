
import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, message } from 'antd';
import ModalLogin from "./ModalLogin/modalLogin";
import ModalRegister from "./ModalRegister/ModalRegister";
import { postLogout } from "../../helpers/apis";
import {
    MenuOutlined
} from "@ant-design/icons";
import ModalForgotPassword from "./ModalForgotPassword/ModalForgotPassword";
import { setIsOpenLogin, setIsOpenRegister } from "../../Redux/counterSlice";
import { useDispatch } from "react-redux";
const Header = () => {
    const [isHeaderFixed, setIsHeaderFixed] = useState(true);
    const [isOpenNav, setIsOpenNav] = useState(false)


    useEffect(() => {
        const handleScroll = () => {
            // const scrollY = window.scrollY;
            // if (scrollY > 100) {
            //     setIsHeaderFixed(true);
            // } else {
            //     setIsHeaderFixed(false);
            // }

        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    function windowScroll() {
        setIsOpenNav(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const handleLogout = () => {
        postLogout()
            .then((res) => {
                if (res.status === 200) {
                    message.success(res.data.message)
                    localStorage.clear()
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 2000)
                }
            }).catch((e) => {
                console.log(e)
            })
    }
    const items = [
        {
            key: '1',
            label: (
                <Link onClick={() => setIsOpenNav(false)} to="/trang-ca-nhan">{localStorage.getItem("userName")}</Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link onClick={() => setIsOpenNav(false)} to="/trang-ca-nhan">Trang cá nhân</Link>
            ),
        },
        {
            key: '3',
            label: (
                <button onClick={handleLogout}  >
                    Đăng xuất
                </button>
            ),
        },

    ];
    useEffect(() => {
        if (localStorage.getItem("sendEmail")) {

            setIsOpenRegister(true)
        }
        localStorage.setItem("sendEmail", "")
    }, [localStorage.getItem("sendEmail")])

    const dispatch = useDispatch()
    const location = useLocation()
    return <header className={` ${isHeaderFixed ? " fixed bg-[#000000] " : `${location.pathname === "/trang-ca-nhan" && " bg-[#000000]  text-[#fff] "} ${location.pathname !== "/trang-ca-nhan" ? "absolute " : ""}`}   top-0 left-0 w-full z-[999]`}>
        <div className="container">
            <div className="flex">
                <div className="flex-1 flex items-center">
                    <Link onClick={windowScroll} to="">
                        <img className=" max-h-[40px] sm:max-h-[50px] cursor-pointer" src="/assets/img/a5.png" alt="" />
                    </Link>
                </div>
                <div>
                    <nav>
                        <ul className="flex gap-8 items-center ">
                            <div className={`${isOpenNav ? "max-lg:fixed top-0 left-0 w-full z-30 max-lg:h-[100vh] " : "max-lg:hidden"} `}>
                                <div onClick={() => setIsOpenNav(false)} className="max-ls:absolute top-0 left-0 w-full max-lg:h-[100vh] z-[5] "></div>
                                <ul className={`lg:flex gap-8 items-center  ${isOpenNav ? "absolute z-30 top-0 right-0 " : "max-lg:hidden"} max-lg:pt-10 top-0 right-0 lg:relative max-lg:flex-col max-lg:w-[250px] max-lg:min-h-[100vh] max-lg:bg-[#000] z-10`}>
                                    <li className={`hover:text-[#FF5421]  ${location.pathname === '/' ? "text-[#FF5421]" : ` text-[#fff] ${isHeaderFixed ? "text-[#fff]" : `text-[#fff] ${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "lg:text-[#000]"}`} `}  cursor-pointer uppercase font-semibold `}><Link className="block max-lg:pl-5 lg:py-[34px] py-[20px] " to="/" onClick={windowScroll}  >Trang chủ</Link></li>
                                    <li className={`  hover:text-[#FF5421] ${location.pathname === '/gioi-thieu' ? "text-[#FF5421]" : ` text-[#fff] ${isHeaderFixed ? "text-[#fff]" : ` ${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "lg:text-[#000]"}`} `}   cursor-pointer uppercase font-semibold `}><Link className="block max-lg:pl-5 lg:py-[34px] py-[20px]" to="/gioi-thieu" onClick={windowScroll}  >Giới Thiệu</Link></li>
                                    <li className={`  hover:text-[#FF5421] ${location.pathname === '/khoa-hoc' ? "text-[#FF5421]" : `text-[#fff] ${isHeaderFixed ? "text-[#fff]" : ` ${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "lg:text-[#000]"}`} `}   cursor-pointer uppercase font-semibold `}><Link className="block max-lg:pl-5 lg:py-[34px] py-[20px]" to="/khoa-hoc" onClick={windowScroll} >Khóa học</Link></li>
                                    <li className={`   hover:text-[#FF5421] ${location.pathname === '/tin-tuc' ? "text-[#FF5421]" : `text-[#fff] ${isHeaderFixed ? "text-[#fff]" : ` ${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "lg:text-[#000]"}`} `}   cursor-pointer uppercase font-semibold  `}><Link className="block max-lg:pl-5 lg:py-[34px] py-[20px]" to="/tin-tuc" onClick={windowScroll}  >Tin tức</Link></li>
                                    <li className={`   hover:text-[#FF5421] ${location.pathname === '/mua-sach' ? "text-[#FF5421]" : `text-[#fff] ${isHeaderFixed ? "text-[#fff]" : ` ${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "lg:text-[#000]"}`} `}   cursor-pointer uppercase font-semibold  `}><Link className="block max-lg:pl-5 lg:py-[34px] py-[20px]" to="/mua-sach" onClick={windowScroll}  >Quầy Sách</Link></li>
                                    <li className={`  hover:text-[#FF5421] ${location.pathname === '/lien-he' ? "text-[#FF5421]" : `text-[#fff] ${isHeaderFixed ? "text-[#fff]" : ` ${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "lg:text-[#000]"}`} `}   cursor-pointer uppercase font-semibold `}><Link className="block max-lg:pl-5 lg:py-[34px] py-[20px]" to="/lien-he" onClick={windowScroll}  >Liên hệ</Link></li>


                                    <li className={`max-lg:pl-5 py-[20px]  font-semibold flex gap-3 max-lg:flex-col  ${isHeaderFixed ? "text-[#fff] " : `${`${location.pathname === "/trang-ca-nhan" ? "text-[#fff]" : "text-[#000]"} max-lg:text-[#fff] `}`} `} >
                                        {


                                            localStorage.getItem("userName")
                                                ?

                                                <Dropdown className="" menu={{ items }} placement="bottomLeft">
                                                    <div className=" hover:text-[#FF5421] cursor-pointer uppercase font-semibold  " >Quản trị</div>
                                                </Dropdown>
                                                :
                                                <>
                                                    <button onClick={() => {

                                                        dispatch(setIsOpenLogin(true))
                                                        setIsOpenNav(false)
                                                    }} className="max-w-[150px] uppercase cursor-pointer bg-[#ff5421] px-4 text-[#fff] py-2.5 rounded-md ">Đăng nhập</button>
                                                    <button onClick={() => {
                                                        dispatch(setIsOpenRegister(true))

                                                        setIsOpenNav(false)
                                                    }} className=" max-w-[150px] uppercase cursor-pointer bg-[#ff5421]  text-[#fff] px-4 py-2.5 rounded-md ">Đăng ký</button>
                                                </>




                                        }

                                    </li>
                                </ul>
                            </div>


                            <li onClick={() => setIsOpenNav(true)} className={`lg:hidden max-lg:py-[20px] cursor-pointer hover:text-[#FF5421] ${isHeaderFixed && "text-[#fff]"} `}><MenuOutlined /></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <ModalForgotPassword />
        <ModalLogin />
        <ModalRegister />
    </header>
}
export default Header