import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counter",
  initialState: {
    user: null,
    checkedit: false,
    editcategory: null,
    isOpenLogin: false,
    isOpenRegister: false,
    valueEmail: null,
    isOpenForgotPassword: false
  },
  reducers: {
    setUser: (state, data) => {
      state.user = data.payload;
    },
    setCheckedit: (state, data) => {
      state.user = data.payload;
    },
    setEditcategory: (state, data) => {
      state.user = data.payload;
    },
    setIsOpenLogin: (state, data) => {

      state.isOpenLogin = data.payload;
    },
    setIsOpenRegister: (state, data) => {
      state.isOpenRegister = data.payload;
    },
    setInjetValueEmail: (state, data) => {
      state.valueEmail = data.payload;
    },
    setIsOpenForgotPassword: (state, data) => {
      state.isOpenForgotPassword = data.payload;
    }
  },
});

export const { setUser,
  setCheckedit,
  setEditcategory,
  setIsOpenForgotPassword,
  setIsOpenLogin,
  setIsOpenRegister ,
  setInjetValueEmail
} = counterSlice.actions;
export default counterSlice.reducer;
