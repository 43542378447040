import React, {Suspense, useState} from 'react';
import Footer from '../footer/Footer';
import Header from '../Header/Header';
import Loading from '../Loading/Loading';
const LayoutPage = ({ children }) => {

    return (
        <div>
            <Header />
            <div>
                <Suspense fallback={<Loading />} >
                    {children}
                </Suspense>
            </div>
            <Footer/>
        </div>
    )
}
export default LayoutPage