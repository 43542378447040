import { instanceAxios } from "./request";


export const getallCategory = () => instanceAxios.post(`/admin/type/list
`)
export const editCategory = (data) => instanceAxios.post(`/admin/type/update
`, data)
export const deleteCategory = (id) => instanceAxios.post(`/admin/type/delete`, id)
export const createCategory = (data) => instanceAxios.post(`/admin/type/create
`, data)
export const apiAdmin = () => instanceAxios.post(`/admin/dashboard`,)
export const apiAdminCollaborators = () => instanceAxios.post(`/admin/dashboard/collaborators`,)

export const deleteUser = (users) => instanceAxios.post("/admin/user/delete/batch", users)
export const postReceipt = () => instanceAxios.post("/admin/receipt/list")
export const postReceiptBook = () => instanceAxios.post("/admin/receipt/list/book")
export const postReceiptGuest = () => instanceAxios.post("/admin/receipt/list/guest")
export const postUpdateReceipt = (receipt) => instanceAxios.post("/admin/receipt/update", receipt)
export const postUpdateReceiptBook = (receipt) => instanceAxios.post("/admin/receipt/update/book", receipt)

export const GetAllUser = (search) => instanceAxios.post("/admin/user/list",search)
export const updateUser = (user) => instanceAxios.post("/admin/user/update", user)
export const updateUserRole = (data) => instanceAxios.post("/admin/user/role", data)

export const adminProduct = () => instanceAxios.post("/admin/course/list")
export const adminProductDelete = (id) => instanceAxios.post("/admin/course/delete",id)
export const adminCategory_product = () => instanceAxios.post("/admin/type/list")
export const admin_blogs = (data) => instanceAxios.post("/admin/blog/list")
export const admin_contacts = (data) => instanceAxios.post("/contact/list")
export const admin_delete_contacts = (id) => instanceAxios.post("/contact/delete",id)
export const admin_educations = (data) => instanceAxios.post("/admin/education/list")
export const admin_education_by_id = (id) => instanceAxios.post("/admin/education/detail",id)
export const admin_carete_education = (data) => instanceAxios.post("/admin/education/create",data)
export const admin_update_education = (data) => instanceAxios.post("/admin/education/update",data)
export const delete_Education = (id) => instanceAxios.post("/admin/education/delete", id)
export const create_Education_item = (data) => instanceAxios.post("/admin/education/item/create", data)
export const update_Education_item = (data) => instanceAxios.post("/admin/education/item/update", data)
export const delete_Education_item = (id) => instanceAxios.post("/admin/education/item/delete", id)

export const admin_createBlog = (data) => instanceAxios.post("/admin/blog/create", data)
export const delete_Blog = (id) => instanceAxios.post("/admin/blog/delete", id)

export const getoneBlog = (id) => instanceAxios.post("/blog/detail", id)
export const updateBlog = (data) => instanceAxios.post('/admin/blog/update', data)
export const prdocuctAdmin = (data) => instanceAxios.post('/admin/course/create', data)
export const prdocuctUpdateAdmin = (data) => instanceAxios.post('/admin/course/update', data)
export const listTeacher = () => instanceAxios.post('/admin/lecturer/list')
export const createTeacherApi = (data) => instanceAxios.post('/admin/lecturer/create',data)
export const updateTeacher = (data) => instanceAxios.post('/admin/lecturer/update',data)
export const updateConfig = (data) => instanceAxios.post('/admin/settings/update',data)
export const getConfig = () => instanceAxios.post('/admin/settings')
export const detailTeacher = (data) => instanceAxios.post('/admin/lecturer/detail',data)
export const deleteTeacher = (data) => instanceAxios.post('/admin/lecturer/delete',data)
export const detailCourse = (data) => instanceAxios.post('/admin/course/detail', data)
export const addItemCourse = (data) => instanceAxios.post('/admin/item/create', data)
export const updateItemCourse = (data) => instanceAxios.post('/admin/item/update', data)
export const deleteItemCourse = (id) => instanceAxios.post('/admin/item/delete', {id:id})
export const addNewModule = (data) => instanceAxios.post('/admin/category/create', data)
export const deleteModule = (id) => instanceAxios.post('/admin/category/delete', {id:id})
export const updateNewModule = (data) => instanceAxios.post('/admin/category/update', data)
export const getItemOfCourse = (id) => instanceAxios.post(`admin/course/items`, {id:id})
export const getoneUser = (email) => instanceAxios.post(`/user/get-by-email`, email)
export const getCollaborators = () => instanceAxios.get(`/user/collaborators`)
export const regsiterCollaboratorsApi = () => instanceAxios.post(`/user/collaborators`)
export const updateCollaboratorsApi = (code) => instanceAxios.post(`/user/collaborators/update`,{code:code})
